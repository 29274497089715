class StockReportTypes {
    static get defaultType() {
        return { id: 'interstat_procurement', name: "Interstat ievedumu atskaite" };
    }

    static get procurementAdditionalDetailsTypeId() {
        return 'procurement_additional_details';
    }

    static get orderItemsWithCatalogItemUnitsTypeId() {
        return 'order_items_with_catalog_item_units';
    }

    static types() {
        return [
            { id: 'interstat_procurement', name: "Interstat ievedumu atskaite" },
            { id: 'interstat_order', name: "Interstat izvedumu atskaite" },
            { id: 'procurement_additional_details', name: "Ievedums raž. kg. atskaite" },
            { id: 'order_items_with_catalog_item_units', name: "Vienību pārdošanas atskaite" },
        ];
    }
}

export default StockReportTypes;
