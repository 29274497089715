<template>
  <Listbox as="div" v-model="selected">
    <ListboxLabel
      v-if="showPlaceholder"
      class="block text-sm font-medium text-gray-700 dark:text-gray-400"
    >
      {{ placeholder }}
    </ListboxLabel>
    <div class="mt-1 relative group">
      <ListboxButton
        class="
          bg-white
          dark:bg-gray-750
          relative
          w-full
          border
          rounded-md
          shadow-sm
          pl-3
          pr-12
          py-2
          text-left
          cursor-default
          focus:outline-none focus:ring-0
          sm:text-sm
        "
        :class="[
          errors
            ? 'border-red-500 text-red-500'
            : 'border-gray-300 dark:border-gray-500',
          !selected[id] ? 'text-gray-500' : '',
        ]"
      >
        <span
          class="block truncate"
          :class="[
            errors ? 'text-red-500' : selectedName ? 'dark:text-gray-200' : '',
          ]"
          >{{ selectedName ? selectedName : defaultValue[name] }}</span
        >
        <span
          class="
            absolute
            inset-y-0
            right-0
            flex
            items-center
            pr-2
            pointer-events-none
          "
          :class="selectedName ? 'dark:text-gray-200' : ''"
        >
          <ChevronDownIcon class="w-5 h-5" />
        </span>
      </ListboxButton>

      <template v-if="clearAble">
        <button
          class="
            absolute
            inset-y-0
            right-6
            flex
            items-center
            pr-2
            cursor-pointer
            z-10
            transition
            duration-300
            ease-in-out
            dark:text-gray-200
            hover:text-main4
            dark:hover:text-main4
            opacity-0
            group-hover:opacity-100
          "
          @click="clearSelect"
        >
          <XIcon class="w-5 h-5" />
        </button>
      </template>

      <p
        v-if="errors"
        class="mt-2 text-sm text-red-500 absolute -bottom-5 left-0 pl-3"
      >
        {{ errors[0] }}
      </p>

      <transition
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <ListboxOptions
          class="
            absolute
            z-20
            mt-1
            w-full
            bg-white
            dark:bg-gray-750
            shadow-lg
            max-h-60
            rounded-md
            py-1
            text-base
            ring-1 ring-black ring-opacity-5
            overflow-auto
            focus:outline-none
            sm:text-sm
          "
          :class="top ? 'bottom-10' : ''"
        >
          <ListboxOption
            as="template"
            v-for="item in items"
            :key="item[id]"
            :value="item"
            v-slot="{ active, selected }"
            @click="click = true"
            class=""
          >
            <li
              :class="[
                active
                  ? 'text-white bg-main4'
                  : 'text-gray-900 dark:text-gray-300',
                'cursor-default select-none relative py-2 pl-3 pr-9 text-left list-option-li',
              ]"
            >
              <span
                :class="[
                  selected ? 'font-semibold' : 'font-normal',
                  'block truncate hover:text-white',
                ]"
              >
                <span class="">
                  {{ getName(item) }}
                </span>

                <template v-if="additionalFields">
                  <template
                    v-for="(text, index) in additionalFields"
                    :key="index"
                  >
                    <div class="block text-gray-400 text-sm">
                      <span>{{item[text]}}</span>
                    </div>
                  </template>
                </template>

                <template v-if="additionalFieldsRows">
                  <template
                    v-for="(row, index) in additionalFieldsRows"
                    :key="index"
                  >
                    <div>
                      <template v-for="(text, index) in row" :key="index">
                        <span class="text-gray-400 text-sm mr-2">{{
                          item[text]
                        }}</span>
                      </template>
                    </div>
                  </template>
                </template>
              </span>

              <span
                v-if="selected"
                :class="[
                  active ? 'text-white' : 'text-main4',
                  'absolute inset-y-0 right-0 flex items-center pr-4',
                ]"
              >
                <CheckIcon class="w-5 h-5" />
              </span>
            </li>
          </ListboxOption>

          <template v-if="items.length < 1">
            <li
              :class="[
                'cursor-default select-none relative py-2 pl-3 pr-9 text-left',
              ]"
            >
              <span :class="['block truncate text-gray-400']">
                Diemžēl nekas netika atrasts
              </span>
            </li>
          </template>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>

<script>
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/vue";

import {
  ChevronDownIcon,
  XIcon,
  CheckIcon,
} from "@heroicons/vue/solid";

export default {
  name: "Select",
  components: {
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
    ChevronDownIcon,
    XIcon,
    CheckIcon,
  },
  data() {
    return {
      selected: {},
      selectedName: null,
      defaultValue: null,
      click: false,
    };
  },
  watch: {
    selected() {
      this.$emit("update:modelValue", this.selected);
      this.$emit("change");
      this.getSelectedName();

      if (this.click) {
        this.$emit("click");
        this.click = false;
      }
    },
    items() {
      if (this.modelValue) {
        this.selected = this.modelValue;
      } else {
        this.selected = { empty: true };
        const defaultValue = {};
        defaultValue[this.id] = null;
        defaultValue[this.name] = this.placeholder;
        defaultValue.empty = true;
        this.defaultValue = defaultValue;
        this.selectedName = null;
      }
    },
  },
  created() {
    if (this.modelValue) {
      this.selected = this.modelValue;
    } else {
      const defaultValue = {};
      defaultValue[this.id] = null;
      defaultValue[this.name] = this.placeholder;
      defaultValue.empty = true;

      this.defaultValue = defaultValue;
    }
    this.getSelectedName();
  },
  props: {
    modelValue: {
      // type: Object,
    },
    items: {
      required: true,
      // type: Object || Array,
    },
    placeholder: {
      type: String,
      default: "Select",
    },
    showPlaceholder: {
      type: Boolean,
      default: true,
    },
    id: {
      type: String,
      default: "id",
    },
    name: {
      type: String,
      default: "name",
    },
    label: {
      type: String,
      default: null,
    },
    top: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default: null,
    },
    smallText: {
      type: String,
      default: null,
    },
    clearAble: {
      type: Boolean,
      default: false,
    },
    additionalFields: {
      type: Array,
      default: null,
    },
    additionalFieldsRows: {
      type: Array,
      default: null,
    },
  },
  emits: ["update:modelValue", "change", "click"],
  methods: {
    getName(item) {
      let name = item[this.name];
      if (this.name.includes(".")) {
        const nameArray = this.name.split(".");
        let currentValue = item;
        nameArray.forEach((name) => {
          currentValue = currentValue[name];
        });
        name = currentValue;
      }
      return name;
    },
    getSelectedName() {
      if (this.selected[this.name]) {
        this.selectedName = this.selected[this.name];
      } else {
        this.selectedName = this.getName(this.selected);
      }
    },
    clearSelect(event) {
      event.preventDefault();
      this.click = true;
      this.selected = {
        empty: true,
        id: null,
      };
      this.selected[this.name] = this.placeholder;
    },
  },
};
</script>

<style>
</style>