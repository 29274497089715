import { vm } from "@/main";
import moment from "moment";

class Dates {
  static lastDateOfYear(year = null) {
    if (year == null) {
      year = moment().format("YYYY");
    }

    return moment(year + "-12-31").format("YYYY-M-D");
  }

  static firstDayOfYear(year = null) {
    if (year == null) {
      return moment()
          .startOf('year')
          .format("YYYY-MM-DD");
    }

    return moment(year + "-01-01")
        .format("YYYY-MM-DD");
  }

  static firstDayOfMonth() {
    return moment()
      .startOf("month")
      .format("YYYY-MM-DD");
  }

  static firstDayOfWeek() {
    const today = moment();
    const startOfWeek = today.startOf('isoWeek');
    return startOfWeek.format("YYYY-MM-DD")
  }

  static oneMonthAgo() {
    return moment()
        .subtract(1,'months')
        .format("YYYY-MM-DD");
  }

  static format(date) {
    return moment(date).format("DD.MM.YYYY");
    // return vm.config.globalProperties.$moment(date).format("DD.MM.YYYY");
  }

  static today() {
    return moment().format("YYYY-MM-DD");
    // return vm.config.globalProperties.$moment(date).format("DD.MM.YYYY");
  }

  static getYearsForSelect() {
    let years = [];
    const currentYear = new Date().getFullYear();
    for (let i= currentYear; i>= currentYear - 20; i--) {
      years.push({ id: i.toString(), name: i.toString() });
    }
    return years;
  }

  static getCurrentYearForSelect() {
    const currentYear = new Date().getFullYear();
    return { id: currentYear.toString(), name: currentYear.toString() };
  }
}

export default Dates;
