<template>
  <Content :filter="false" :showFilterDefault="true" :search="false">

    <template v-slot:content>

      <div class="w-full bg-white dark:bg-gray-750 p-4 rounded-lg">
        <div class="flex flex-wrap sm:flex-nowrap items-center mr-6 ">
          <div class="w-64 mr-3">
            <Select v-model="filter.selectedReportType"
                    placeholder="Atskaite"
                    :items="reportTypes"
                    :clearAble="false"
                    :showPlaceholder="true"
                    :errors="errors.type"
                    @change="updateFilters"
            />
          </div>
          <template v-if="!showYear">
            <div class="w-48 mr-3">
              <Input type="date"
                     v-model="filter.from"
                     placeholder="Datums no"
                     :show-placeholder="true"
                     :errors="errors.from"
                     @change="updateFilters"
              />
            </div>
            <div class="w-48">
              <Input type="date"
                     v-model="filter.to"
                     placeholder="Datums līdz"
                     :show-placeholder="true"
                     :errors="errors.to"
                     @change="updateFilters"
              />
            </div>
          </template>
          <template v-else>
            <Select v-model="filter.selectedYear"
                    placeholder="Gads"
                    :items="years"
                    :clearAble="false"
                    :showPlaceholder="true"
                    :errors="errors.year"
                    @change="updateFilters"
            />
          </template>

          <template v-if="showUniqueUnitsCheckbox">
            <div class="w-64 pt-5 pl-3">
              <Checkbox v-model="filter.showUniqueUnits" text="Rādīt unikālos kodus" @change="updateFilters" />
            </div>
          </template>

        </div>

        <template v-if="showCustomers">
          <div class="max-w-2xl mt-4">
            <Autocomplete placeholder="Klients" v-model="customer" :errors="errors.customer"
                          url="/api/fetch_customers" :additionalFields="['reg_nr']"
            />
          </div>
        </template>


        <div class="flex flex-wrap justify-end gap-4 mt-6 border-T border-gray-200 dark:border-gray-700 pb-6">
          <template v-if="!loading">
            <button type="button"
                    class="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-button2-from to-button2-to hover:from-button2-to hover:to-button2-from focus:outline-none focus:border-none transition duration-500 ease-in-out"
                    @click="createReportExcel">
              Izveidot atskaiti
            </button>
          </template>
          <template v-else>
            <Loading/>
          </template>
          <button type="button"
                  class="inline-flex items-center px-4 py-2 border border-gray-300 dark:border-gray-500 shadow-sm text-sm font-medium rounded-md text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-750 hover:bg-gray-50 dark:hover:bg-gray-770 focus:outline-none focus:ring-0"
                  @click="clearFilters">
            Atcelt
          </button>
        </div>
      </div>

    </template>

  </Content>
</template>

<script>

import {mapGetters} from "vuex";
import Input from "@/components/Components/Input";
import Select from "@/components/Components/Select";
import Loading from "@/components/Components/Loading";
import Checkbox from "@/components/Components/Checkbox";
import Dates from "@/services/Helpers/dates";
import StockReportTypes from "@/services/Helpers/StockReportTypes";
import Autocomplete from "../../../components/Components/Autocomplete.vue";

export default {
  name: "StockReports",
  components: {
    Autocomplete,
    Checkbox,
    Input,
    Select,
    Loading
  },
  data: () => ({
    filter: {
      from: Dates.firstDayOfMonth(),
      to: new Date().toISOString().substr(0, 10),
      selectedYear: Dates.getCurrentYearForSelect(),
      selectedReportType: StockReportTypes.defaultType,
      showUniqueUnits: false,
    },
    showUniqueUnitsCheckbox: false,
    showYear: false,
    showCustomers: false,
    years: Dates.getYearsForSelect(),
    reportTypes: StockReportTypes.types(),
    queryParams: null,
    customer: null
  }),
  created() {
    // Get query params from URL
    this.queryParams = this.$route.query;
    // If there are no URL params set default params
    if (Object.keys(this.queryParams).length === 0) {
      this.replaceQueryParams()
    }
    // Update component data from query prams
    this.setFiltersFromParams();
  },
  mounted() {
    this.$store.dispatch('removeAllFormsForDisplay');
  },
  computed: {
    ...mapGetters({
      catalogItemUnits: 'catalogItemUnits',
      loading: "loading",
      errors: "errors",
    }),
  },
  methods: {
    updateFilters() {
      if (this.filter.selectedReportType.id === StockReportTypes.procurementAdditionalDetailsTypeId) {
        this.showYear = true;
        this.filter.from = this.filter.selectedYear ? Dates.firstDayOfYear(this.filter.selectedYear.id) : Dates.firstDayOfYear();
        this.filter.to = this.filter.selectedYear ? Dates.lastDateOfYear(this.filter.selectedYear.id) : Dates.lastDateOfYear();
      } else {
        this.showYear = false;
      }

      this.showUniqueUnitsCheckbox = this.filter.selectedReportType.id === StockReportTypes.orderItemsWithCatalogItemUnitsTypeId;
      this.showCustomers = this.filter.selectedReportType.id === StockReportTypes.orderItemsWithCatalogItemUnitsTypeId;

      this.replaceQueryParams();
      this.queryParams = this.$route.query;
    },
    clearFilters() {
      this.filter.from = Dates.firstDayOfMonth()
      this.filter.to = new Date().toISOString().substr(0, 10);
      this.filter.selectedYear = Dates.getCurrentYearForSelect();
      this.filter.selectedReportType = StockReportTypes.defaultType;
      this.filter.showUniqueUnits = false;

      this.$router.replace({
        query: {
          from: this.filter.from,
          to: this.filter.to,
          year: this.filter.selectedYear.id,
          type: this.filter.selectedReportType.id,
          showUniqueUnits: this.filter.showUniqueUnits,
        }
      });
    },
    replaceQueryParams() {
      this.$router.replace({
        query: {
          from: this.filter.from,
          to: this.filter.to,
          year: this.filter.selectedYear ? this.filter.selectedYear.id : null,
          type: this.filter.selectedReportType ? this.filter.selectedReportType.id : null,
          showUniqueUnits: this.filter.showUniqueUnits,
        }
      });
    },
    setFiltersFromParams() {
      this.filter.from = this.queryParams.from ? this.queryParams.from : this.filter.from;
      this.filter.to = this.queryParams.to ? this.queryParams.to : this.filter.to;
      this.filter.showUniqueUnits = this.queryParams.showUniqueUnits;
      this.filter.selectedYear = this.years.find(year => {
        return year.id == this.queryParams.year;
      })
      this.filter.selectedReportType = this.reportTypes.find(type => {
        return type.id == this.queryParams.type;
      })
    },
    createReportExcel() {
      let data = this.queryParams
      data['customer_id'] = this.customer ? this.customer.id : ''

      this.$store.dispatch("setErrors", {});
      this.$store.dispatch('getStockReportExcel', this.queryParams)
    }
  }
}
</script>