<template>
  <div class="relative">
    <label v-show="placeholder && showPlaceholder" for="input" class="block text-sm font-medium text-gray-700 dark:text-gray-400 text-left mb-1 whitespace-nowrap">
      {{placeholder}}
    </label>
    <div class="relative">
      <input :placeholder="placeholder" @input="input" v-model="inputValue" class="dark:bg-gray-750 shadow-sm focus:ring-0 focus:border-gray-300 block w-full sm:text-sm  rounded-md" :class="errors && errors[0] ? 'border-red-500 text-red-500 placeholder-red-500' : 'border-gray-300 dark:border-gray-500 dark:text-gray-200'" :type="type" :disabled="disabled" :min="type == 'number' ? min : ''" :max="type == 'number' ? max : ''" />
    </div>
    <p v-if="errors && errors[0]" class="text-xs text-red-500 absolute top-full -mt-0.5 left-0 pl-3 pt-1" id="input2-error">{{errors[0]}}</p>
  </div>
</template>

<script>
export default {
  name: "Input",
  data() {
    return {
      inputValue: null,
    }
  },
  props: {
    modelValue: {},
    label: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    errors: {
      type: Array,
    },
    type: {
      type: String,
      default: "text",
    },
    disabled: {
      type: Boolean,
      default: false
    },
    min: {
      type: String,
      default: "0",
    },
    max: {
      type: String,
      default: "999",
    },
    showPlaceholder: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    this.inputValue = this.modelValue;
  },
  watch: {
    modelValue() {
      this.inputValue = this.modelValue
    }
  },
  emits: ['update:modelValue'],
  methods: {
    input() {
      this.$emit('update:modelValue', this.inputValue) // previously was `this.$emit('input', title)`
    },
  }
}
</script>

<style>

</style>