<template>
  <div class="relative flex items-start group">
    <div class="flex items-center h-5">
      <input
        :id="`checkbox-${name}`"
        aria-describedby="checkbox-description"
        :name="`checkbox-${name}`"
        type="checkbox"
        class="focus:ring-0 focus:outline-none h-4 w-4 border-gray-300 rounded"
        v-model="checkboxValue"
        @change="change"
      />
    </div>
    <div class="ml-3 text-sm" :class="labelContainerClass">
      <label
        :for="`checkbox-${name}`"
        class="font-medium text-gray-700 dark:text-gray-300 cursor-pointer"
        :class="[labelClass, errors ? 'text-red-500' : '']"
        >{{ text }}</label
      >
      <p v-if="smallText" id="checkbox-description" class="text-gray-500">
        {{ smallText }}
      </p>
    </div>
    <p
      v-if="errors"
      class="mt-2 text-sm text-red-500 absolute -bottom-5 left-0 pl-7"
    >
      {{ errors[0] }}
    </p>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";

export default {
  name: "Checkbox",
  data() {
    return {
      checkboxValue: false,
      name: null,
    };
  },
  watch: {
    modelValue: function () {
      this.checkboxValue = Boolean(this.modelValue);
    },
  },
  props: {
    modelValue: {
      // type: Boolean,
    },
    text: {
      type: String,
      default: null,
    },
    smallText: {
      type: String,
      default: null,
    },
    value: {
      default: null,
    },
    errors: {
      type: Array,
    },
    labelClass: {
      type: String,
      default: null,
    },
    labelContainerClass: {
      type: String,
      default: null,
    },
  },
  emits: ["update:modelValue"],
  methods: {
    change() {
      if (this.value) {
        const data = this.checkboxValue ? this.value : null;
        this.$emit("update:modelValue", data);
      } else {
        this.$emit("update:modelValue", this.checkboxValue);
      }
    },
  },
  mounted() {
    this.checkboxValue = this.modelValue;
    this.name = uuidv4();
    if (this.value == this.modelValue) {
      this.checkboxValue = true;
    }
  },
};
</script>

<style>
</style>